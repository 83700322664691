<template>
    <div class="card" style="min-height: 200px">
        <div class="table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <th>Date & Time</th>
                    <th>Investigation Name</th>
                    <th>Expected Delivery Date</th>
                    <th>Payment Status</th>
                    <th>Status</th>
                    <th>Complete Date & Time</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, i) in tableItems" :key="i">
                    <td> {{ item.date }}</td>
                    <td> {{ item.investigationName }}</td>
                    <td> {{ item.deliveryDate }}</td>
                    <td> {{ item.paymentStatus }}</td>
                    <td> {{ item.status }}</td>
                    <td> {{ item.completeDate }}</td>
                    <td>
                        <button class="btn btn-primary btn-sm"
                                style="cursor: pointer"
                        >
                          <i class="fas fa-eye"></i>
                        </button>
                    </td>
                </tr>

                </tbody>
            </table>
            <p v-if="!tableItems.length" class="text-center mt-5">No data</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ListTable',

        props: {
            tableItems: Array
        }
    }
</script>